<template>
  <div class="ManagerAllFactures">


    <!-- SET COLLABORATEUR ALERT -->
    <CModal
      :show.sync="isCreatingFacture"
      :no-close-on-backdrop="true"
      title="Créer une nouvelle facture"
      size="lg"
      color="dark"
    >
      <CRow>
        <CCol>
          <vue-bootstrap-typeahead
            v-model="addFactureClientName"
            :data="addFactureClients"
            :serializer="item => item.name"
            @hit="addFactureClientObject = $event"
             placeholder="Chercher votre client par nom"
          />
        </CCol>
      </CRow>

      <CRow class="mt-3">
        <CCol>
          <CSelect
            :value.sync="addFactureMission"
            label="Mission"
            :horizontal='true'
            :options="addFactureMissionsSelectOptions"
            @input="$v.addFactureMission.$touch()"
            :isValid="$v.addFactureMission.$dirty ? !$v.addFactureMission.$error : null"
            custom
          />
        </CCol>
      </CRow>



      <CButton
        color="outline-primary"
        shape="pill" block class="px-4"
        @click="$router.push('/add-new-facture/'+addFactureMission)"
        :disabled="$v.addFactureClientObject.$invalid || $v.addFactureMission.$invalid">
        Créer une facture
      </CButton>


      <template #footer>
        <CButton @click="isCreatingFacture = false" color="dark">Annuler</CButton>
      </template>
    </CModal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>


    <CRow>
      <CCol>
        <h1> Factures </h1>
      </CCol>

      <CCol v-if="company.iban && company.bic && company.titulaire" class="text-right align-self-center">
        <CButton
          @click="isCreatingFacture = true"
          size="sm"
          shape="pill"
          color="success">
            <CIcon name="cil-plus"/> Créer une nouvelle facture
        </CButton>
      </CCol>

      <CCol v-else class="text-right align-self-center" sm="6">
        <small v-if="userGroupContains(['BOSS'])" class="text-danger">
            Veuillez
            <router-link to="/company/">
              <strong class="text-danger">renseigner les coordonnées bancaires de votre entreprise </strong>
            </router-link>
            pour pouvoir créer une facture
        </small>

        <small v-else class="text-danger">
            Votre responsable Eclerk doit renseigner les coordonnées bancaires de votre entreprise
            pour que vous puissiez émettre une facture.
        </small>
      </CCol>
    </CRow>


    <CCard class="mt-3">
      <CCardBody>
        <CRow>
          <CCol xl="5">
            <CInput
              label="Début de date de facturation"
              type="date"
              :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
              v-model="startDateFilter">
            </CInput>
          </CCol>
          <CCol xl="5">
            <CInput
              label="Fin de date de facturation"
              type="date"
              :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
              v-model="endDateFilter">
            </CInput>
          </CCol>
          <CCol xl="2">
            <CButton
              color="primary"
              variant="outline"
              shape="pill"
              block
              @click="getAllFacturesForManager">
            Filtrer par date
          </CButton>
          </CCol>
        </CRow>

        <CRow class="mt-3 mt-xl-0">
          <CCol>
            <a class="eclerk-link" href="#" @click="reinitializeWeek">
              Depuis le début de la semaine
            </a>
            <br class="d-sm-block d-md-none">

            <a class="ml-0 ml-md-2 eclerk-link" href="#" @click="reinitializeMonth">
              Depuis le début du mois
            </a>
          </CCol>
        </CRow>

        <CRow class="mt-3">

          <CCol sm="4">
            <CInputCheckbox
              label="Tous les clients"
              :checked.sync="isAllClientsFilter"
              :custom="true"
            >
            </CInputCheckbox>
          </CCol>
          <CCol v-if="!isAllClientsFilter" sm="8" class="mt-2 mt-sm-0">
            <vue-bootstrap-typeahead
              v-model="clientFilterName"
              :data="filterClients"
              :serializer="item => item.name"
              @hit="clientFilterObject = $event"
               placeholder="Chercher votre client par nom"
            />

          </CCol>
        </CRow>

      </CCardBody>
    </CCard>

    <CRow style="font-size: 1.2rem;">
      <CCol xl="6">
        <strong class="text-primary" style="font-size: 1.6rem;">{{nbFacturesAPayer}}</strong> factures à payer dont <strong class="text-danger" style="font-size: 1.6rem;">{{nbFacturesEnRetard}}</strong> en retard
      </CCol>
      <CCol xl="6" class="text-right">
        <CButton
            v-if="!isLateFacturesFilter"
            color="danger"
            shape="pill"
            @click="getAllLateFacturesForManager">
          Toutes les factures en retard
        </CButton>
        <CButton
            v-else
            color="primary"
            shape="pill"
            @click="getAllFacturesForManager">
          Voir tout
        </CButton>
      </CCol>
    </CRow>

    <CRow class="mt-4">
      <CCol xl="3">
        <CWidgetIcon
          text="montant total HT"
          color="primary"
          :icon-padding="false"
        >
          <strong>{{String(formatThousandSeparatorNumber(parseFloat(montantTotalHT).toFixed(0))) + ' €'}}</strong>
        </CWidgetIcon>
      </CCol>
      <CCol xl="3">
        <CWidgetIcon
          :header="String()"
          text="montant total TTC"
          color="warning"
          :icon-padding="false"
        >
          <strong>{{String(formatThousandSeparatorNumber(parseFloat(montantTotalTTC).toFixed(0))) + ' €'}}</strong>
        </CWidgetIcon>
      </CCol>
      <CCol xl="3">
        <CWidgetIcon
          text="payé"
          color="success"
          :icon-padding="false"
        >
          <strong>{{String(formatThousandSeparatorNumber(parseFloat(montantTotalPaid).toFixed(0))) + ' €'}}</strong>
        </CWidgetIcon>
      </CCol>
      <CCol xl="3">
        <CWidgetIcon
          text="reste à payer"
          color="danger"
          :icon-padding="false"
        >
          <strong>{{String(formatThousandSeparatorNumber(parseFloat(montantTotalToPay).toFixed(0))) + ' €'}}</strong>
        </CWidgetIcon>
      </CCol>
    </CRow>


    <CRow>
      <CCol>
        <CCard >
          <CCardBody>
            <CRow>
              <CCol>
                ⚠️ Si certaines factures n'apparaissent pas, veuillez vérifier les dates de facturation choisies ou alors
                la liste des
                <router-link to="/archives/factures">
                  factures archivées
                </router-link>
              </CCol>
            </CRow>

            <CRow>
              <CCol>
                <CDataTable
                  class="cursor-table"
                  :items-per-page="50"
                  sorter
                  pagination
                  :items="facturesRender"
                  :fields="facturesRenderFields"
                  @row-clicked="goToFacturePage"
                  :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
                >
                <template #over-table>
                  <CRow class="mt-2">
                    <CCol sm="6">
                      <exportTableDataComponent
                        v-if="chosenFactures.length > 0"
                        v-bind:data="chosenFactures"
                        v-bind:fields="facturesFileRenderFields"
                        name="Eclerk - Factures"
                        label="Exporter la sélection"
                      />
                    </CCol>
                    <CCol sm="6" class="text-right">
                      <exportTableDataComponent
                        v-bind:data="facturesRender"
                        v-bind:fields="facturesFileRenderFields"
                        name="Eclerk - Factures"
                        label="Exporter la liste"
                      />
                    </CCol>
                  </CRow>
                </template>
                <template #actions="{item}">
                  <td @click.stop>
                    <CInputCheckbox
                      :custom="true"
                      :inline="true"
                      @update:checked="checkFactureBox(item)"
                    />
                  </td>

                </template>
                <template #client="{item}">
                  <td>
                   <nobr><CIcon v-c-tooltip="{content: 'Envoyé au client',placement: 'right'}" v-if="item.has_been_sent" name="cil-send" /> {{item.client}}</nobr>
                  </td>
                </template>
                <template #date_echeance="{item}">
                  <td>
                   <nobr><span>{{$dayjs(item.date_echeance).format('DD/MM/YYYY')}}</span></nobr>
                  </td>
                </template>
                <template #montant_total_ht="{item}">
                  <td>
                    <nobr>{{formatThousandSeparatorNumber(parseFloat(item.montant_total_ht).toFixed(2))}} €</nobr>
                  </td>
                </template>
                <template #montant_total_ttc="{item}">
                  <td>
                    <nobr>{{formatThousandSeparatorNumber(parseFloat(item.montant_total_ttc).toFixed(2))}} €</nobr>
                  </td>
                </template>
                <template #montant_total_paye="{item}">
                  <td>
                    <nobr>{{formatThousandSeparatorNumber(parseFloat(item.montant_total_paye).toFixed(2))}} €</nobr>
                  </td>
                </template>
                <template #reste_a_payer="{item}">
                  <td>
                    <nobr>{{formatThousandSeparatorNumber(parseFloat(item.reste_a_payer).toFixed(2))}} €</nobr>
                  </td>
                </template>

                <template #no-items-view>
                  <CRow>
                    <CCol class="text-center">
                      Aucune facture
                    </CCol>
                  </CRow>
                </template>
                </CDataTable>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minValue, integer} from 'vuelidate/lib/validators'

import renderMixins from '@/mixins/renderMixins'
import userMixins from '@/mixins/userMixins'

import { APIUserConnected } from '@/api/APIUserConnected'
import { APIManagerConnected } from '@/api/APIManagerConnected'

import Loading from 'vue-loading-overlay';

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import exportTableDataComponent from '@/components/exportTableDataComponent'

import 'vue-loading-overlay/dist/vue-loading.css';

const apiUserConnected = new APIUserConnected()
const apiManagerConnected = new APIManagerConnected()


export default {
  name: 'ManagerAllFactures',
  components: {
    Loading,
    VueBootstrapTypeahead,
    exportTableDataComponent
  },
  mixins: [
    validationMixin,
    renderMixins,
    userMixins
  ],
  data: function () {
    var today = this.$dayjs()
    var oneMonthAgo = today.subtract('3', 'months')
    return {

      // ------------- ADD FACTURE -----------
      isCreatingFacture: false,

      addFactureClients: [],
      addFactureClientName: '',
      addFactureClientObject: {
        id: '',
        name: ''
      },

      addFactureMissionsSelectOptions: [{label: '', value: ''}],
      addFactureMission: '',

      // ------------- FACTURE -----------
      startDateFilter: oneMonthAgo.format('YYYY-MM-DD'),
      endDateFilter: today.format('YYYY-MM-DD'),

      isAllClientsFilter: true,
      filterClients: [],
      clientFilterName: '',
      clientFilterObject: {
        id: '',
        name: ''
      },

      factures: [],
      facturesRender: [],
      facturesRenderFields: [
        { key: "actions", label: ''},
        { key: "facture_number", label: "#"},
        { key: "exercice_comptable", label: 'Exercice'},
        { key: "client", label: "Client"},
        { key: "intitule", label: "Intitulé"},
        { key: "date_echeance", label: "Date d'échéance", sortable: true },
        { key: "montant_total_ht", label: "Montant total HT", sortable: true },
        { key: "montant_total_ttc", label: "Montant total TTC", sortable: true },
        { key: "montant_total_paye", label: "Montant payé", sortable: true },
        { key: "reste_a_payer", label: "Reste à payer", sortable: true },
      ],
      facturesFileRenderFields: {
        'Exercice comptable': 'exercice_comptable',
        '#': 'facture_number',
        'Client': 'client',
        'Mission': 'mission',
        'Libellé': 'intitule',
        'Date de facturation': 'date_facturation',
        "Date d'échéance": "date_echeance",
        'Montant total HT': 'montant_total_ht',
        'Montant total TTC': 'montant_total_ttc',
        'Montant total payé': 'montant_total_paye',
        'Reste à payer': 'reste_a_payer'
      },
      isAllManagerFacturesDashboardLoading: false,

      nbFacturesAPayer: 0,
      nbFacturesEnRetard: 0,
      montantTotalHT: 0,
      montantTotalTTC: 0,
      montantTotalPaid: 0,
      montantTotalToPay: 0,

      // ------------- LATE FACTURES -----
      isLateFacturesFilter: false,

      // ------------- COMPANY -----------
      company: {
        company_name: '',
        siret: '',
        forme_sociale: '',
        capital: 0,
        address: '',
        phone_country_code: '',
        phone_number: '',
        email: '',
        iban: '',
        bic: '',
        titulaire: ''
      },
      isCompanyLoading: false,

      // ------------- CLIENTS -----------
      clients: [],
      clientsSelectOptions: [],
      isAllClientsLoading: false,

      // ------------- MISSION -----------
      missions: [],
      isAllMissionsLoading: false,

      // ------------- DOWNLOAD FACTURES -------
      chosenFactures: []

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isAllManagerFacturesDashboardLoading || this.isAllClientsLoading || this.isAllMissionsLoading || this.isCompanyLoading) {
        return true
      }
      return false
    },
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.getAllFacturesForManager()
    this.getCompany()
  },

  validations: {
    addFactureClientObject: {
      id: {
        required,
        minValue: minValue(1),
        integer
      }
    },
    addFactureMission: {
      required,
      minValue: minValue(1),
      integer
    },
  },

  watch: {

    // TODO When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    addFactureClientName(newQuery) {
      if (newQuery == '') {
        this.addFactureClients = []
        this.addFactureClientObject = {
          id: '',
          name: ''
        }
        this.addFactureMissionsSelectOptions = [{label: '', value: ''}]
        this.addFactureMission = ''
      }
      else if (newQuery.length >= 2) {
        apiUserConnected.searchClientByName(this.token, newQuery)
        .then((res) => {
          this.addFactureClients = res.data
        })
      }
    },

    addFactureClientObject(newObject) {
      if (newObject.id === parseInt(newObject.id, 10)) {
        if (newObject.id >= 1) {
          apiUserConnected.getClientAllMissions(this.token, newObject.id)
          .then((result) => {
            if (result.data.length == 0) {
              this.addFactureMissionsSelectOptions = [{label: '', value: ''}]
            }
            else if (result.data.length == 1) {
              this.addFactureMissionsSelectOptions = [{label: result.data[0].name, value: result.data[0].id}]
              this.addFactureMission = result.data[0].id
            }
            else {
              var add_facture_missions_final_array = [{label: '', value: ''}]
              for (var i = 0; i < result.data.length; i++) {
                add_facture_missions_final_array.push(
                  {
                    'label': result.data[i].name,
                    'value': result.data[i].id,
                  }
                )
              }
              this.addFactureMissionsSelectOptions = add_facture_missions_final_array
            }
          })
          .catch(() => {
            this.$store.commit('openGlobalErrorModal')
          })
        }
      }
    },

    clientFilterName(newQuery) {
      if (newQuery == '') {
        this.filterClients = []
        this.clientFilterObject = {
          id: '',
          name: ''
        }
      }
      else if (newQuery.length >= 2) {
        apiUserConnected.searchClientByName(this.token, newQuery)
        .then((res) => {
          this.filterClients = res.data
        })
      }
    },

    clientFilterObject(newObject) {
      if (newObject.id === parseInt(newObject.id, 10)) {
        if (newObject.id >= 1) {
          this.getAllFacturesForManager()
        }
      }
      else if (newObject.id == '') {
        this.getAllFacturesForManager()
      }
    },

    isAllClientsFilter(newBoolean) {
      if (newBoolean) {
        this.clientFilterObject = {
          id: '',
          name: ''
        }
      }
    },

    factures: function (newFactures) {

      // PAIEMENT STATISTIQUES
      this.nbFacturesEnRetard = 0
      this.nbFacturesAPayer = 0
      this.montantTotalHT = 0
      this.montantTotalTTC = 0
      this.montantTotalPaid = 0
      this.montantTotalToPay = 0

      // PAIEMENT DETAILLED
      this.facturesRender = []

      for (var i = 0; i < newFactures.length; i++) {
        var temp_data = {
          'id': newFactures[i].id,
          'facture_number': newFactures[i].facture_number,
          'exercice_comptable': newFactures[i].exercice_comptable,
          'client': newFactures[i].client_name,
          'mission': newFactures[i].mission_name,
          'intitule': newFactures[i].facture_title,
          'date_facturation': newFactures[i].date_facturation,
          'date_echeance': newFactures[i].date_echeance,
          'montant_total_ht': parseFloat(newFactures[i].montant_total_ht) - parseFloat(newFactures[i].remise_totale_ht),
          'montant_total_ttc': newFactures[i].montant_total_ttc,
          'montant_total_paye': newFactures[i].montant_total_paid,
          'reste_a_payer': parseFloat(newFactures[i].montant_total_ttc) - parseFloat(newFactures[i].montant_total_paid),
          'has_been_sent': newFactures[i].has_been_sent
        }

        var today = this.$dayjs()
        var echeanceDate = this.$dayjs(newFactures[i].date_echeance)
        this.montantTotalHT = parseFloat(this.montantTotalHT) + parseFloat(newFactures[i].montant_total_ht) - parseFloat(newFactures[i].remise_totale_ht)
        this.montantTotalTTC = parseFloat(this.montantTotalTTC) + parseFloat(newFactures[i].montant_total_ttc)

        if (parseFloat(newFactures[i].montant_total_ht) - parseFloat(newFactures[i].remise_totale_ht) < 0) {
          temp_data['_classes'] = 'table-secondary'
          this.montantTotalToPay = parseFloat(this.montantTotalToPay) + parseFloat(newFactures[i].montant_total_ttc)
        }
        else if (parseFloat(newFactures[i].montant_total_paid).toFixed(2) == parseFloat(newFactures[i].montant_total_ttc).toFixed(2)) {
          temp_data['_classes'] = 'table-info'
          this.montantTotalPaid = parseFloat(this.montantTotalPaid) + parseFloat(newFactures[i].montant_total_paid)
        }
        else if (today > echeanceDate) {
          temp_data['_classes'] = 'table-danger'
          this.nbFacturesEnRetard ++
          this.nbFacturesAPayer ++
          this.montantTotalPaid = parseFloat(this.montantTotalPaid) + parseFloat(newFactures[i].montant_total_paid)
          this.montantTotalToPay = parseFloat(this.montantTotalToPay) + parseFloat(newFactures[i].montant_total_ttc) - parseFloat(newFactures[i].montant_total_paid)
        }
        else {
          this.nbFacturesAPayer ++
          this.montantTotalPaid = parseFloat(this.montantTotalPaid) + parseFloat(newFactures[i].montant_total_paid)
          this.montantTotalToPay = parseFloat(this.montantTotalToPay) + parseFloat(newFactures[i].montant_total_ttc) - parseFloat(newFactures[i].montant_total_paid)
        }
        this.facturesRender.push(temp_data)
      }
    },
  },
  methods: {
    // -------------- GETTERS -----------------------
    getAllFacturesForManager () {
      this.isAllManagerFacturesDashboardLoading = true
      apiManagerConnected.getAllFacturesForManager(this.token, this.startDateFilter, this.endDateFilter, this.clientFilterObject.id)
      .then((result) => {
        this.factures = result.data
        this.isLateFacturesFilter = false
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllManagerFacturesDashboardLoading = false
      })
    },

    getAllLateFacturesForManager () {
      this.isAllManagerFacturesDashboardLoading = true
      apiManagerConnected.getAllLateFacturesForManager(this.token)
      .then((result) => {
        this.factures = result.data
        this.isLateFacturesFilter = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllManagerFacturesDashboardLoading = false
      })
    },

    getCompany () {
      this.isCompanyLoading = true
      apiUserConnected.getCompany(this.token)
      .then((result) => {
        this.company = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isCompanyLoading = false
      })
    },

    // ------------ Filter functions ----------------
    reinitializeWeek () {
      var today = this.$dayjs()
      this.endDateFilter = today.format('YYYY-MM-DD')
      this.startDateFilter = today.startOf('week').format('YYYY-MM-DD')
      this.getAllFacturesForManager()
    },
    reinitializeMonth () {
      var today = this.$dayjs()
      this.endDateFilter = today.format('YYYY-MM-DD')
      this.startDateFilter = today.startOf('month').format('YYYY-MM-DD')
      this.getAllFacturesForManager()
    },

    checkFactureBox (item) {
      const index = this.chosenFactures.indexOf(item)
      if (index === -1){
        this.chosenFactures.push(item)
      }
      else {
        this.chosenFactures.splice(index, 1)
      }
    },

    // ------------ ROUTER FUNCTIONS -------------------
    goToFacturePage(item) {
      var routeData = this.$router.resolve('modify-facture/' + item.id);
      window.open(routeData.href, '_blank');
    }


  }
}
</script>

<style>
.no-border-table td {
  border-top: None;
}

.thead-clickable {
  cursor: pointer;
}

.thead-clickable:hover{
  color: black !important;
}

</style>
